import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import MainLayout from '../components/MainLayout'

import moonImage from '../assets/404/moon.svg'
import Image from "next/legacy/image"

function NotFoundPage() {
  return (
    <MainLayout>
      <Container>
        <Row className="mt-3 mb-4">
          <Col>
            <div
              className="text-center"
              style={{
                fontSize: '1.5rem',
              }}
            >
              Oops...
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col lg={{ span: 2, offset: 5 }}>
            <Image src={moonImage} alt="Moon" />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <div
              className="fw-bold text-center"
              style={{
                fontSize: '12.5rem',
                lineHeight: '12.5rem',
              }}
            >
              404
            </div>
            <div
              className="text-center fw-light"
              style={{
                fontSize: '1.5rem',
              }}
            >
              PAGE NOT FOUND
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default NotFoundPage
